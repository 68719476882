import React, { Component, Fragment } from "react";
import { Col, Form, Row, Spinner, Table } from "react-bootstrap";
import './App.css';
import { FormControl, FormControlLabel, MenuItem, Radio, Select } from "@mui/material";

class LossCodeList extends Component {
  getVariant = (code) => {
    const { selectedLossCode } = this.props;

    if (
      selectedLossCode?.code === code?.code &&
      selectedLossCode != null &&
      selectedLossCode !== ""
    ) {
      return "table-active";
    } else {
      return this.code === null ? "table-light" : null;
    }
  };

  // getChecked = (code) => {
  //   const { selectedLossCode } = this.props;
  //   return selectedLossCode?.code === code?.code &&
  //     selectedLossCode != null &&
  //     selectedLossCode !== ""
  //     ? "checked"
  //     : null;
  // };
  getChecked = (code) => {
    const { selectedLossCode } = this.props;
    return selectedLossCode?.code === code?.code;
  };

  // renderLossCodes = () => {
  //   const { lossCodeList, handleLossCodeSelect } = this.props;

  //   return lossCodeList.map((code) => {
  //     return (
  //       <tr
  //         key={code.code}
  //         className={this.getVariant(code)}
  //         onClick={() => handleLossCodeSelect(code)}
  //       >
  //         <td>
  //           <Form.Check
  //             type="radio"
  //             aria-label={code.code}
  //             label={code.description.substring(0, 50)}
  //             checked={this.getChecked(code)}
  //             readOnly
  //           />
  //         </td>
  //       </tr>
  //     );
  //   });
  // };

  renderLossCodes = () => {
    const { lossCodeList } = this.props;

    return lossCodeList.map((code) => (
      <MenuItem key={code.code} value={code.code}>
        {code.description.substring(0, 50)}
      </MenuItem>
    ));
  };
  render() {
    // const { loading, lossCodeList } = this.props;
    const { lossCodeList, handleLossCodeSelect, selectedLossCode ,loading} = this.props;


    return (
      <Fragment>
        {loading && (
          <Row>
            <Col>
              <Spinner animation="border"></Spinner>
              <br />
              <span className="spinner-text">Re-loading loss code list...</span>
            </Col>
          </Row>
        )}
        {!loading && (
          <>
          {/* <Table style={{border:"2px solid red"}}>
            <tbody className="custom-scrollbar">
              {lossCodeList.length > 0 ? (
                this.renderLossCodes()
              ) 
              : (
                <tr>
                  <td>No loss codes available.</td>
                </tr>
              )}
            </tbody>
          </Table> */}
             <h2>Product</h2>
          <FormControl sx={{ width: "50%" }}>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={selectedLossCode?.code || (lossCodeList.length > 0 ? lossCodeList[0].code : "")}          onChange={(e) => handleLossCodeSelect(lossCodeList.find(code => code.code === e.target.value))}
          MenuProps={{
            PaperProps: {
              className: 'custom-scrollbar',
            },
          }}
        >
          {lossCodeList.length > 0 ? (
            this.renderLossCodes()
          ) : (
            <MenuItem disabled>
              No loss codes available.
            </MenuItem>
          )}
        </Select>
      </FormControl>
</>
        )}
      </Fragment>
    );
  }
}

export default LossCodeList;
