import React, { Component } from 'react'
import moment from "moment";
import { Spinner, Row, Col } from "react-bootstrap";
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Grid, List, ListItemButton, ListSubheader, } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Morning from "../img/Morning.svg"
import NotMorning from "../img/Morning-not-available.svg"
import Afternoon from "../img/Afternoon.svg"
import NotAfterNoon from "../img/Afternoon-not-available.svg"
import Evening from "../img/Evening.svg"
import NotEvening from "../img/Evening-not-available.svg"
import SoftAvailability from "../img/appointment (1) (2) (1).svg"
import firmAvailability from "../img/appointmentfirm.svg"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const scrollbar = {
    "&::-webkit-scrollbar": {
        width: "5px", // Set the width of the scrollbar
        borderRadius: "5px" /* Add rounded corners */,
        backgroundColor: "#f0f0f0" /* Set the background color of the track */,
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888", // Set the color of the scrollbar thumb
        borderRadius: "5px", // Set the border radius of the thumb
    },
};

export default class ForceAssignmentDialog extends Component {
    state = { firstWeek: [], secondWeek: [], showFirstWeek: true, showSecondWeek: false };

    componentDidMount() {
        const newArr = this.props.data.slice(0, 7)
        const secondNewArr = this.props.data.slice(7, 14)
        this.setState({ firstWeek: newArr, secondWeek: secondNewArr })
    }

    handleForward = () => {
        this.setState({ showFirstWeek: false })
    }

    handleBackward = () => {
        this.setState({ showFirstWeek: true })
    }

    render() {
        const { ForceAssignmentDialogOpen, handleClose, data, openConfirmationDialog, appointmentWorking } = this.props

        const isTimeSlotPresent = (availability, str) => {
            return availability && availability.some(slot => slot === str);
        }

        const { firstWeek, secondWeek, showFirstWeek, } = this.state

        return (
            <>
                <BootstrapDialog
                    //   onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={ForceAssignmentDialogOpen}
                    PaperProps={{
                        sx: {
                            maxWidth: '100vw', // or 'lg', 'xl', etc.
                            width: '100vw', // Set width as needed
                            height: "97vh",
                            borderRadius: "20px",
                            paddingRight: "1rem",
                            paddingLeft: "1rem",
                        },
                    }}
                >

                    <DialogTitle sx={{ m: 0, p: 2, textAlign: "center", fontSize: "14px", fontWeight: "600", lineHeight: "28px" }} id="customized-dialog-title">
                        Force Book<br />
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent sx={{ ...scrollbar, overflowY: "scroll", }}>
                        <Grid container xs={12} style={{ height: "100%", justifyContent: "center" }}>
                            {
                                showFirstWeek && firstWeek?.map((item, index) => (<>
                                    <Grid key={index} item xs={1.7} style={{ borderLeft: ".5px dotted #EFEFEF", borderRight: ".5px dotted #EFEFEF", borderBottom: ".5px dotted #EFEFEF" }}>
                                        <List
                                            sx={{ ...scrollbar, overflowY: "hidden", width: '100%', maxWidth: 360, bgcolor: 'background.paper', padding: "0", position: "relative" }}
                                            component="nav"
                                            aria-labelledby="nested-list-subheader"
                                            subheader={
                                                <ListSubheader style={{ background: "#F2F4FF", textAlign: "center", fontSize: "14px", position: "sticky", top: 0, lineHeight: "1.8rem" }} component="div" id="nested-list-subheader">
                                                    {/* {index == 0 && (<ArrowBackIosNewIcon style={{ fontSize: "12px", position: "absolute", left: 20, top: 20 }}
                                                    // onClick={() => decreaseBtnDisable && hanDleBackward()} 
                                                    />)} */}
                                                    {item.day} <br />
                                                    {item.date}
                                                    {index == (firstWeek.length - 1) && (<ArrowForwardIosIcon style={{ fontSize: "12px", position: "absolute", right: 20, top: 20, cursor: 'pointer' }}
                                                        onClick={() => this.handleForward()}
                                                    />)}
                                                </ListSubheader>

                                            }
                                        >
                                            {
                                                item.availability.map((subItems, subIndex) => (
                                                    <>
                                                    {/* // <ListItemButton style={{ marginTop: "1rem", width: "100%", paddingLeft: "4px", paddingRight: "4px", paddingBottom: "0px", border: "1px solid rgba(234, 234, 234, 1)" }}> */}
                                                    <Grid container xs={12} style={{ height: "8rem", marginTop: "2%", width: "100%", paddingLeft: "4px", paddingRight: "4px", paddingBottom: "0px", border: "1px solid rgba(234, 234, 234, 1)", alignItems: "flex-start", justifyContent: "space-evenly" }}>
                                                        <Grid container item xs={12} sx={{ padding: "0px" }} style={{ alignItems: "center" }}>
                                                            <Grid item xs={3}>
                                                                {
                                                                    (subItems?.serviceProvider === "A&E" && subItems?.provider === "A&E"
                                                                    ) ? (
                                                                        <img src={firmAvailability} alt='user-pic' height="24px" width="24px" />
                                                                    ) : (
                                                                        <img src={SoftAvailability} alt='user-pic' height="24px" width="24px" />
                                                                    )
                                                                }
                                                            </Grid>
                                                            {/* <Grid item xs={1}>

                                                                </Grid> */}
                                                            <Grid item xs={9} >
                                                                <Typography onClick={() => openConfirmationDialog("ForceBook", subItems, subIndex, item.date)} sx={{ fontSize: "11px", wordWrap: "break-word", color: 'rgba(2, 160, 252, 1)', cursor: "pointer" }}>
                                                                    {subItems?.serviceProvider}
                                                                </Typography>

                                                            </Grid>
                                                            {/* <Grid item xs={2}>

                                                                </Grid> */}


                                                        </Grid>
                                                        <Grid container item xs={12} style={{ alignItems: "top" }}>

                                                            {
                                                                (subItems?.serviceProvider != "A&E"&& subItems?.provider !== "A&E") ? (
                                                                    <>
                                                                        <Grid item>
                                                                            <span style={{ fontSize: "10px" }}>Availability : </span>
                                                                        </Grid>
                                                                        <Grid item style={{ alignItems: "top" }}>
                                                                            {
                                                                                isTimeSlotPresent(subItems?.timeBand, "morning") ? (
                                                                                    <Grid item style={{ marginLeft: "5px", marginTop: "3px" }}>
                                                                                        <img src={Morning} alt='user-pic' height="24px" width="24px" />
                                                                                    </Grid>
                                                                                ) :
                                                                                    (
                                                                                        <Grid item style={{ marginLeft: "5px", marginTop: "3px" }}>
                                                                                            <img src={NotMorning} alt='user-pic' height="24px" width="24px" />
                                                                                        </Grid>
                                                                                    )
                                                                            }
                                                                        </Grid>
                                                                        <Grid item style={{ alignItems: "top" }}>
                                                                            {
                                                                                isTimeSlotPresent(subItems?.timeBand, "afternoon") ? (
                                                                                    <Grid item style={{ marginLeft: "5px", marginTop: "3px" }}>
                                                                                        <img src={Afternoon} alt='user-pic' height="24px" width="24px" />
                                                                                    </Grid>
                                                                                ) :
                                                                                    (
                                                                                        <Grid item style={{ marginLeft: "5px", marginTop: "3px" }}>
                                                                                            <img src={NotAfterNoon} alt='user-pic' height="24px" width="24px" />
                                                                                        </Grid>
                                                                                    )
                                                                            }
                                                                        </Grid>
                                                                        <Grid item style={{ alignItems: "top" }}>
                                                                            {
                                                                                isTimeSlotPresent(subItems?.timeBand, "evening") ? (
                                                                                    <Grid item style={{ marginLeft: "5px", marginTop: "3px" }}>
                                                                                        <img src={Evening} alt='user-pic' height="24px" width="24px" />
                                                                                    </Grid>
                                                                                ) :
                                                                                    (
                                                                                        <Grid item style={{ marginLeft: "5px", marginTop: "3px" }}>
                                                                                            <img src={NotEvening} alt='user-pic' height="24px" width="24px" />
                                                                                        </Grid>
                                                                                    )
                                                                            }
                                                                        </Grid>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Grid item>
                                                                            <span style={{ fontSize: "12px" }}>Availability : </span>
                                                                        </Grid>
                                                                        {
                                                                            subItems?.timeBand?.map((timeData) => (
                                                                                <Grid item style={{ alignItems: "top" }}>
                                                                                    <Grid style={{ border: "1px solid rgba(234, 234, 234, 1)", borderRadius: "8px", background: "rgba(245, 245, 245, 1)", marginLeft: "5px", marginBottom: "10px", padding: "0 3px", height: "24px" }}>
                                                                                        <span style={{ fontSize: "11px" }}> {timeData?.slotName}</span>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            ))
                                                                        }

                                                                    </>
                                                                )
                                                            }
                                                        </Grid>
                                                        {
                                                            (subItems?.sameDayCutOff || subItems?.nextDayCutOff) && (
                                                                <>

                                                                    <Grid container item xs={12} style={{ alignItems: "top", flexWrap: "wrap" }}>
                                                                        {
                                                                            subItems?.sameDayCutOff && (
                                                                                <Grid xs={12} item>
                                                                                    <span style={{ fontSize: '10px' }}>   Same Day Cut-Off: {subItems.sameDayCutOff}{" " + subItems.sameDayAmPm} </span>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                        {
                                                                            subItems.nextDayCutOff && (
                                                                                <Grid xs={12} item>
                                                                                    <span style={{ fontSize: '10px' }}> Next Day CutOff: {subItems.nextDayCutOff}{" " + subItems.nextDayAmPm}</span>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                </>
                                                            )
                                                        }
                                                    </Grid>
                                                    {/* // </ListItemButton> */}
                                                    </>

                                                ))
                                            }

                                        </List>
                                    </Grid>
                                </>))
                            }
                            {
                                !showFirstWeek && secondWeek?.map((item, index) => (<>
                                    <Grid key={index} item xs={1.7} style={{ borderLeft: ".5px dotted #EFEFEF", borderRight: ".5px dotted #EFEFEF", borderBottom: ".5px dotted #EFEFEF" }}>
                                        <List
                                            sx={{ ...scrollbar, overflowY: "hidden", width: '100%', maxWidth: 360, bgcolor: 'background.paper', padding: "0", position: "relative" }}
                                            component="nav"
                                            aria-labelledby="nested-list-subheader"
                                            subheader={
                                                <ListSubheader style={{ background: "#F2F4FF", textAlign: "center", fontSize: "14px", position: "sticky", top: 0, lineHeight: "1.8rem" }} component="div" id="nested-list-subheader">
                                                    {index == 0 && (<ArrowBackIosNewIcon style={{ fontSize: "12px", position: "absolute", left: 20, top: 20, cursor: 'pointer' }}
                                                        onClick={() => this.handleBackward()}
                                                    />)}
                                                    {item.day} <br />
                                                    {item.date}
                                                    {/* {index == (data.length - 1) && (<ArrowForwardIosIcon style={{ fontSize: "12px", position: "absolute", right: 20, top: 20 }}
                                                    //  onClick={() => increaseBtnDisable && hanDleForward()} 
                                                    />)} */}
                                                </ListSubheader>

                                            }
                                        >
                                            {
                                                item.availability.map((subItems, subIndex) => (
                                                    // <ListItemButton style={{ marginTop: "1rem", width: "100%", paddingLeft: "4px", paddingRight: "4px", paddingBottom: "0px", border: "1px solid rgba(234, 234, 234, 1)" }}>
                                                    <Grid container xs={12} style={{ height: "8rem", marginTop: "2%", width: "100%", paddingLeft: "4px", paddingRight: "4px", paddingBottom: "0px", border: "1px solid rgba(234, 234, 234, 1)", alignItems: "flex-start", justifyContent: "space-evenly" }}>
                                                        <Grid container item xs={12} sx={{ padding: "0px" }} style={{ alignItems: "center" }}>
                                                            <Grid item xs={3}>
                                                                {
                                                                    (subItems?.serviceProvider === "A&E") ? (
                                                                        <img src={firmAvailability} alt='user-pic' height="24px" width="24px" />
                                                                    ) : (
                                                                        <img src={SoftAvailability} alt='user-pic' height="24px" width="24px" />
                                                                    )
                                                                }
                                                            </Grid>
                                                            {/* <Grid item xs={1}>

                                                            </Grid> */}
                                                            <Grid item xs={9} >
                                                                <Typography onClick={() => openConfirmationDialog("ForceBook", subItems, subIndex, item.date)} sx={{ fontSize: "11px", wordWrap: "break-word", color: 'rgba(2, 160, 252, 1)', cursor: "pointer" }}>
                                                                    {subItems?.serviceProvider}
                                                                </Typography>

                                                            </Grid>
                                                            {/* <Grid item xs={2}>

                                                            </Grid> */}


                                                        </Grid>
                                                        <Grid container item xs={12} style={{ alignItems: "top" }}>

                                                            {
                                                                subItems?.serviceProvider != "A&E" ? (
                                                                    <>
                                                                        <Grid item>
                                                                            <span style={{ fontSize: "10px" }}>Availability : </span>
                                                                        </Grid>
                                                                        <Grid item style={{ alignItems: "top" }}>
                                                                            {
                                                                                isTimeSlotPresent(subItems?.timeBand, "morning") ? (
                                                                                    <Grid item style={{ marginLeft: "5px", marginTop: "3px" }}>
                                                                                        <img src={Morning} alt='user-pic' height="24px" width="24px" />
                                                                                    </Grid>
                                                                                ) :
                                                                                    (
                                                                                        <Grid item style={{ marginLeft: "5px", marginTop: "3px" }}>
                                                                                            <img src={NotMorning} alt='user-pic' height="24px" width="24px" />
                                                                                        </Grid>
                                                                                    )
                                                                            }
                                                                        </Grid>
                                                                        <Grid item style={{ alignItems: "top" }}>
                                                                            {
                                                                                isTimeSlotPresent(subItems?.timeBand, "afternoon") ? (
                                                                                    <Grid item style={{ marginLeft: "5px", marginTop: "3px" }}>
                                                                                        <img src={Afternoon} alt='user-pic' height="24px" width="24px" />
                                                                                    </Grid>
                                                                                ) :
                                                                                    (
                                                                                        <Grid item style={{ marginLeft: "5px", marginTop: "3px" }}>
                                                                                            <img src={NotAfterNoon} alt='user-pic' height="24px" width="24px" />
                                                                                        </Grid>
                                                                                    )
                                                                            }
                                                                        </Grid>
                                                                        <Grid item style={{ alignItems: "top" }}>
                                                                            {
                                                                                isTimeSlotPresent(subItems?.timeBand, "evening") ? (
                                                                                    <Grid item style={{ marginLeft: "5px", marginTop: "3px" }}>
                                                                                        <img src={Evening} alt='user-pic' height="24px" width="24px" />
                                                                                    </Grid>
                                                                                ) :
                                                                                    (
                                                                                        <Grid item style={{ marginLeft: "5px", marginTop: "3px" }}>
                                                                                            <img src={NotEvening} alt='user-pic' height="24px" width="24px" />
                                                                                        </Grid>
                                                                                    )
                                                                            }
                                                                        </Grid>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Grid item>
                                                                            <span style={{ fontSize: "12px" }}>Availability : </span>
                                                                        </Grid>
                                                                        {
                                                                            subItems?.timeSlot?.map((timeData) => (
                                                                                <Grid item style={{ alignItems: "top" }}>
                                                                                    <Grid style={{ border: "1px solid rgba(234, 234, 234, 1)", borderRadius: "8px", background: "rgba(245, 245, 245, 1)", marginLeft: "5px", marginBottom: "10px", padding: "0 3px", height: "24px" }}>
                                                                                        <span style={{ fontSize: "11px" }}> {timeData?.slotName}</span>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            ))
                                                                        }

                                                                    </>
                                                                )
                                                            }
                                                        </Grid>
                                                        {
                                                            (subItems?.sameDayCutOff || subItems?.nextDayCutOff) && (
                                                                <>

                                                                    <Grid container item xs={12} style={{ alignItems: "top", flexWrap: "wrap" }}>
                                                                        {
                                                                            subItems?.sameDayCutOff && (
                                                                                <Grid xs={12} item>
                                                                                    <span style={{ fontSize: '10px' }}>   Same Day Cut-Off: {subItems.sameDayCutOff}{" " + subItems.sameDayAmPm} </span>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                        {
                                                                            subItems.nextDayCutOff && (
                                                                                <Grid xs={12} item>
                                                                                    <span style={{ fontSize: '10px' }}> Next Day CutOff: {subItems.nextDayCutOff}{" " + subItems.nextDayAmPm}</span>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                </>
                                                            )
                                                        }
                                                    </Grid>
                                                    // </ListItemButton>
                                                ))
                                            }

                                        </List>
                                    </Grid>
                                </>))
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ paddingBottom: "2rem" }}>
                        <Grid container xs={12} style={{ fontSize: "14px", alignItems: "center" }}>
                            <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                                <img src={SoftAvailability} alt='user-pic' height="24px" width="24px" />
                                &nbsp;&nbsp; Soft Availability
                            </Grid>
                            <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                                <img src={Morning} alt='user-pic' height="24px" width="24px" />
                                &nbsp; &nbsp; Morning
                            </Grid>
                            <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                                <img src={Afternoon} alt='user-pic' height="24px" width="24px" />
                                &nbsp; &nbsp; Afternoon
                            </Grid>
                            <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                                <img src={Evening} alt='user-pic' height="24px" width="24px" />
                                &nbsp; &nbsp; Evening
                            </Grid>
                            {/* <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                                        <img src="/appointment (1) (2) (1)rejected.svg" alt='user-pic' height="24px" width="24px" />
                                        &nbsp;&nbsp; Rejected
                                    </Grid> */}
                        </Grid>

                    </DialogActions>
                    {appointmentWorking && (
                        <Row className="mt-5 ps-3 pe-3">
                            <Col>
                                <Spinner animation="border"></Spinner>
                                <br />
                                <span className="spinner-text">Booking appointment...</span>
                            </Col>
                        </Row>
                    )}
                </BootstrapDialog>
            </>
        )
    }
}
